body {
    /*font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;*/
    font-family: 'Open Sans';
    margin: 5px;
}

* {
    padding: 0;
    margin: 0;
}

#mainNav {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #F05454;
    border-radius: 10px 10px 0px 0px;
}

.navbar-header {
    background-color: #222831;
    z-index: 999;
}

footer {
    background-color: #F05454;
    border-radius: 0px 0px 10px 10px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #fff;
    padding: 8px 12px;

}

.navbar {
    overflow: hidden;
}

.navbar a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 17px 36px;
    text-decoration: none;
    font-size: 17px;
    background-color: #30475E;
    border-radius: 10px 10px 10px 10px;
    margin-left: 5px;
    /*height: 5px;*/
    font-weight: bold;
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
}

.navbar a.active {
    background-color: #DDDDDD;
    color: black;
}

.nav-right {
    padding: 1rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    float: right;
    position: relative;
    color: #fff;

}

li {
    float: left;
    list-style-type: none;
    margin-bottom: -7.6px;
    margin-top: 14px;
}

.container {
    display: flex;
    flex-direction: row;

    width: 100%;
    /*height: 10rem;*/
    background-color: #30475E;
    margin: 0 auto;
}
.box {
    margin: 15px;
    border-radius: 10px;
}

.left {
    /*flex-grow: 1;*/
    flex-basis: 25%;
    background-color: #222831;
    /*box-shadow: 2px 2px #DDDDDD;*/
    box-shadow: 1px 2.5px 2px 2.5px #DDDDDD;
}
.right {
    /*flex-grow: 2;*/
    flex-basis: 75%;
    background-color: #DDDDDD;
    box-shadow: 1px 2.5px 2px 2.5px #222831;
}

.cover-photo {
    overflow: hidden;
    position: relative;
    background-size: 100%;
    height: 76vh;
    text-align: left;
    border-radius: 10px 10px 10px 10px;
}

.cover-photo img {
    width: 38rem;
}

.cover-photo h1 {
    margin: 10px;
    color: white;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.text-muted {
    color: #888888;
    margin: 10px;
}

.box-desc {
    margin: 30px;
    color: #30475E;
}

.box-desc article p {
    text-align: center;
    font-size: 18px;
    font-style: italic;
}

.box-desc p.text-muted {
    text-align: right;
    font-size: 16px;
    color: #888888;
    margin-right: 50px;
    margin-top: 50px;
}

.box-desc p.text-email {
    text-align: center;
    font-size: 18px;
    color: #30475E;
    font-weight: bold;
    margin-top: 50px;
}

.box-desc h2.title {
    text-align: center;
}

.hr-item {
    margin: 40px;
    background-color: #F05454;
    height: 1px;
}

.hidden {
    display: none;
}

.box-desc .data-profile p {
    text-align: left;
    margin: 20px 10px 10px 40px;
}

.box-desc form {
    margin: 30px;
    color: #30475E;
}

@media screen and (max-width: 513px) {
    #mainNav {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: #F05454;
        border-radius: 10px 10px 0px 0px;
    }
    
    .navbar-header {
        background-color: #222831;
        z-index: 999;
    }
    
    footer {
        background-color: #F05454;
        border-radius: 0px 0px 10px 10px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: #fff;
        padding: 14px 16px;
    }
    
    .navbar {
        overflow: hidden;
    }
    
    .navbar a {
        float: left;
        color: #f2f2f2;
        text-align: center;
        text-decoration: none;
        font-size: 12px;
        background-color: #30475E;
        border-radius: 10px 10px 10px 10px;
        font-weight: bold;
    }
    
    .navbar a:hover {
        background-color: #ddd;
        color: black;
    }
    
    .navbar a.active {
        background-color: #DDDDDD;
        color: black;
    }

    .nav-right {
       display: block;
       font-size: 12px;
       position: absolute;
       margin-top: 5px;
       margin-left: 21.1rem;
    }

    li {
        float: left;
        list-style-type: none;
        margin-top: 14px;
        margin-left: -3px;
    }

    .container {
        display: flex;
        flex-direction: column;
    
        width: 100%;
        height: 100%;
        background-color: #30475E;
        /*border: 2px solid black;*/
        margin: 0 auto;
    }

    .cover-photo {
        overflow: hidden;
        position: relative;
        background-size: 100%;
        height: 76vh;
        text-align: right;
        border-radius: 10px 10px 10px 10px;
    }
    
    .cover-photo img {
        width: 38rem;
    }
    
    .cover-photo h1 {
        margin-top: -70px;
        color: #fff;
        z-index: 999;
        /*margin-right: 80px;*/
    }

    .text-muted {
        color: #DDDDDD;
        margin: 10px;
        font-style: italic;
    }
}